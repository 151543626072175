import {NavAuth} from "components/utilities/auth-info/auth-info-style";
import Footer from "container/LandingHome/footer";
import Navbar from "container/LandingHome/navbar";
import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {setLang} from "redux/lang/action";
import languageData from "utility/lang.json";
import india from "../../static/img/flag/india.png";
import indonesia from "../../static/img/flag/indonesia.svg";
import phillipines from "../../static/img/flag/philli.png";
import vietnam from "../../static/img/flag/vietnam.png";
import "./styles.css";
import DetailedPrivacyPolicy from "./DetailedTermsAndconditions";

const PrivacyPolicy = (props) => {
  const {lang: language} = props || "";
  // console.log(props.lang);
  const dispatch = useDispatch();

  const lang = localStorage.getItem("lang");
  const flag = localStorage.getItem("country");

  useEffect(() => {
    if (lang && lang !== null && lang !== undefined && !props.lang.lang) {
      dispatch(setLang(lang, flag && flag !== undefined ? flag : "Indonesia"));
    }
    if (!lang) {
      const jsonData = JSON.stringify(languageData["en"]);
      dispatch(setLang(jsonData, "Indonesia"));
    }
    // eslint-disable-next-line
  }, [dispatch, lang, props.lang]);

  const langs = useSelector((state) => state.lang.lang);

  useEffect(() => {
    console.log("<= langs", langs);
  }, [langs]);

  const onFlagChangeHandle = (value, country) => {
    dispatch(setLang(JSON.stringify(value), country));
  };

  const country = (
    <NavAuth>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "India")}
        to="#"
      >
        <img style={{width: 25}} src={india} alt="" />
        <span>English</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Indonesia")}
        to="#"
      >
        <img style={{width: 25}} src={indonesia} alt="" />
        <span>English</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["indo"], "Indonesia")}
        to="#"
      >
        <img style={{width: 25}} src={indonesia} alt="" />
        <span>Bahasa</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Phillipines")}
        to="#"
      >
        <img style={{width: 25}} src={phillipines} alt="" />
        <span>English</span>
      </Link>
      <Link
        onClick={() => onFlagChangeHandle(languageData["en"], "Vietnam")}
        to="#"
      >
        <img style={{width: 25}} src={vietnam} alt="" />
        <span>English</span>
      </Link>
    </NavAuth>
  );

  const countryFlag = (country) => {
    switch (country) {
      case "India":
        return india;
      case "Indonesia":
        return indonesia;
      case "Phillipines":
        return phillipines;
      case "Vietnam":
        return vietnam;

      default:
        return india;
    }
  };
  return (
    <React.Fragment>
      <Navbar
        country={country}
        flag={flag}
        countryFlag={countryFlag}
        language={language}
      />
      {/* <div style={{ position: "fixed", zIndex: 999, right: 0 }}> */}

      {/* </div> */}
      <div className="home">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 home-container">
              <div className="heading">
                {" "}
                {langs && langs.landing_title_1}{" "}
                <span style={{color: "#FFD601"}}>
                  {langs && langs.landing_title_2}
                </span>{" "}
                {langs && langs.landing_title_3}
              </div>
              <div className="sub-heading mt-3">
                {langs && langs.landing_subtitle}
              </div>
              <div className="btn-container">
                <a href="#privacyPolicy">
                  <div className="btn home-btn mt-4">
                    Our Terms And Conditions
                    {/* <span style={{ color: "#FFD601" }}>Free</span> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-5">
              {/* <video className="home-vid" autoPlay loop muted playsinline>
              <source src="https://cdn.modcart.io/landing/home.webm" type="video/webm" />
            </video> */}
              <img
                src="https://cdn.modcart.io/landing/home.gif"
                className="home-vid"
                alt="home"
              />
            </div>
          </div>

          <div className="row marginTop" id="privacyPolicy">
            <DetailedPrivacyPolicy />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({lang: state.lang});
export default connect(mapStateToProps)(PrivacyPolicy);

/* <div id="policy-content" style={{ width: '100%', background: 'url(https://cdn.modcart.io/landing/home-bg.png)' }}>
              <>
                  <h1 className='policyHeading'>Privacy Policy</h1>
                  <div id='policy-content' style={{ height: 'unset' }}>
                      <p className='policyPara'>Modcart provides this Privacy Policy to inform users of our policies and procedures regarding the collection, use and disclosure of personally identifiable information received from users of Modcart’s website, located at the domain modcart.io</p>
                  </div>
              </>
              <>
                  <h1 className='policyHeading'>Cookies</h1>
                  <div id='policy-content' style={{ height: 'unset' }}>
                      <p className='policyPara'>Like many websites, Modcart also uses "cookie" technology to collect additional website usage data and to improve the Modcart Website and the services offered via the Web Site. A cookie is a small data file that Moadcart’s servers transfer to your computer’s hard disk. Modcart does not use cookies to collect personally identifiable information.</p>
                      <p className='policyPara'>Modcart mainly uses "session cookies", which enable certain features of the Modcart Website and services offered via the Modcart Website, to better understand how you interact with the Web Site and services we offer via the Modcart Website, to monitor aggregate usage by our users and web traffic routing on the Modcart Website, and to improve the Modcart Website and services offered via the Modcart Website.</p>
                      <p className='policyPara'>Modcart also uses a persistent cookie that stays on your computer after you leave the Modcart Website, for the sole purpose of ensuring that you receive a variety of selected variety of Modcart content (also called “frequency-capping”).</p>
                      <p className='policyPara'>Most Internet browsers automatically accept cookies. You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit.</p>
                  </div>
              </>

              <div id='policy-content'>
                  <a className='policyLink' href="https://cookiepedia.co.uk/giving-consent-to-cookies" target='__blank'>More Info</a>
                  <a className='policyLink' href="/">Home</a>
              </div>
              <div class="clear"></div>
          </div> */
