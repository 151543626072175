const termsAndConditions = [
  {
    title: "Introduction",
    sections: [
      {
        content: [
          "Welcome to Modcart. By accessing or using our website and services, you agree to comply with and be bound by the following Terms and Conditions. If you do not agree to these terms, please do not use our services.",
        ],
      },
    ],
  },
  {
    title: "Definitions",
    sections: [
      {
        content: [
          "Modcart: Refers to the platform, services, and website provided by Modcart.",
          "User: Refers to any individual or business entity accessing or using the Modcart platform.",
          "Services: Refers to the retail media and coupon management solutions provided by Modcart.",
        ],
      },
    ],
  },
  {
    title: "User Account and Responsibilities",
    sections: [
      {
        content: [
          "Account Creation: Users are required to provide accurate information when creating an account on Modcart. You are responsible for maintaining the confidentiality of your login details.",
          "Account Use: You agree not to misuse the platform, including engaging in illegal activities, violating third-party rights, or disrupting Modcart’s services.",
        ],
      },
    ],
  },
  {
    title: "Coupon Management and Distribution",
    sections: [
      {
        content: [
          "Coupon Creation: Users can create, manage, and distribute digital coupons through the Modcart platform. All information entered for creating coupons must be accurate and lawful.",
          "Distribution: Modcart allows for the distribution of coupons via various channels including QR codes, social media, and email. Users are responsible for ensuring the proper use and redemption of distributed coupons.",
        ],
      },
    ],
  },
  {
    title: "Data Collection and Use",
    sections: [
      {
        content: [
          "Personal Data: Modcart collects and processes data in compliance with our Privacy Policy. By using the platform, you consent to the collection and use of data for service enhancement, marketing, and analytics purposes.",
          "First-Party Data: Retailers and brands using Modcart may collect first-party data from customers during coupon redemption. This data is subject to local privacy laws and should be used responsibly.",
        ],
      },
    ],
  },
  {
    title: "Fees and Payments",
    sections: [
      {
        content: [
          "Subscription Fees: Users agree to pay all applicable subscription fees in accordance with the pricing plan selected during registration.",
          "Payment Terms: Payments must be made on time and in full. Failure to make timely payments may result in suspension or termination of access to Modcart services.",
        ],
      },
    ],
  },
  {
    title: "Intellectual Property",
    sections: [
      {
        content: [
          "Modcart Rights: All content, features, and functionalities provided on the Modcart platform, including logos, trademarks, and software, are the exclusive property of Modcart.",
          "User Content: Users retain ownership of any content or data uploaded to Modcart but grant Modcart a non-exclusive, royalty-free license to use, store, and display the content to provide the services.",
        ],
      },
    ],
  },
  {
    title: "Prohibited Activities",
    sections: [
      {
        content: [
          "Misuse of Services: Users are prohibited from using the platform to engage in unlawful activities, distribute harmful content, or tamper with the system.",
          "Unauthorized Access: You agree not to attempt to gain unauthorized access to any part of Modcart’s platform or to disrupt the integrity of its systems.",
        ],
      },
    ],
  },
  {
    title: "Termination of Service",
    sections: [
      {
        content: [
          "User Termination: Users may terminate their Modcart account at any time by providing notice. Upon termination, any outstanding subscription fees will be due.",
          "Modcart Termination: Modcart reserves the right to suspend or terminate accounts for violation of these Terms or for engaging in prohibited activities.",
        ],
      },
    ],
  },
  {
    title: "Limitation of Liability",
    sections: [
      {
        content: [
          "Modcart shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the platform.",
          "Modcart is not responsible for the misuse of coupons, technical issues affecting access to the platform, or any unauthorized access to user accounts.",
        ],
      },
    ],
  },
  {
    title: "Governing Law",
    sections: [
      {
        content: [
          "These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law principles.",
        ],
      },
    ],
  },
  {
    title: "Changes to the Terms and Conditions",
    sections: [
      {
        content: [
          "Modcart reserves the right to update these Terms and Conditions at any time. Changes will be communicated via email or posted on the website. Continued use of the platform following any changes constitutes acceptance of the updated terms.",
        ],
      },
    ],
  },
  {
    title: "Contact Information",
    sections: [
      {
        content: [
          "For any inquiries or concerns regarding these Terms and Conditions, please contact us at:",
          "Email: Support@modcart.io",
          "Phone: +62 8170711101",
        ],
      },
    ],
  },
];

export default termsAndConditions;
