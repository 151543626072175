import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer  pb-1 desktop-only">
        <div className="container">
          <div className="row mb-5">
            <div className="footer-logo col-md-4 mt-5 text-center">
              <img src="https://cdn.modcart.io/landing/logo-black.png" alt="modcart" />
            </div>
            <div className="col-md-3 mt-5">
              <h4>
                <b>Company</b>
              </h4>
              {/* redirect to main page */}
              <Link to="/"> About Us</Link>
              <br />
              <Link to="/"> Blogs</Link>
            </div>
            <div className="col-md-3 mt-5">
              <h4>
                <b> Trust & Legal</b>
              </h4>
              <Link to="/termsAndConditions"> Terms and Conditions</Link>
              <br />
              <Link to="/privacypolicy"> Privacy Policy</Link>
            </div>
            <div className="col-md-2 mt-5 text-center mobile-only">
              <h4>
                <b>Contact Us</b>
              </h4>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-2 facebook-icon py-3">
                  <a
                    href="https://www.facebook.com/Modcartio-110745874748143"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <FacebookIcon style={{ fontSize: "36px" }} />
                  </a>
                </div>
                <div className="col-2 insta-icon py-3">
                  <a
                    href="https://www.instagram.com/modcart.io/"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <InstagramIcon style={{ fontSize: "36px" }} />
                  </a>
                </div>
                {/* <div className="col-2 py-3">
                <LinkedInIcon style={{ fontSize: "36px" }} />
              </div> */}
                <div className="col-4"></div>
              </div>
            </div>
            <div className="col-md-2 mt-5 text-center desktop-only">
              <h4>
                <b>Contact Us</b>
              </h4>
              <div className="row ">
                <div className="col-3"></div>
                <div className="col-3 facebook-icon py-3">
                  <a
                    href="https://www.facebook.com/Modcartio-110745874748143"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <FacebookIcon style={{ fontSize: "36px" }} />
                  </a>
                </div>
                <div className="col-3 insta-icon py-3">
                  <a
                    href="https://www.instagram.com/modcart.io/"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <InstagramIcon style={{ fontSize: "36px" }} />
                  </a>
                </div>
                {/* <div className="col-2 py-3">
                <LinkedInIcon style={{ fontSize: "36px" }} />
              </div> */}
                <div className="col-3"></div>
              </div>
            </div>
          </div>
          <div className="credits col-12 text-center">
            © 2020-2021 MODCART.IO - All rights reserved.
          </div>
        </div>
      </div>

      {/* Mobile Footer  */}

      <div className="footer  pb-1 mobile-only">
        <div className="container">
          <div className="row mb-5">
            <div className="footer-logo mt-5 text-center">
              <img src="https://cdn.modcart.io/landing/logo-black.png" alt='logo' />
            </div>
            <div className="col-7">
              <div className="col-lg-3 mt-5">
                <h4>
                  <b>Company</b>
                </h4>
                <Link to="/"> About Us</Link>
                <br />
                <Link to="/"> Blogs</Link>
              </div>
              <div className=" col-lg-3 mt-5">
                <h4>
                  <b> Trust & Legal</b>
                </h4>
                <Link to="/termsAndConditions"> Terms and Conditions</Link>
                <br />
                <Link to="/privacypolicy"> Privacy Policy</Link>
              </div>
            </div>
            <div className="col-5">
              <div className="mt-5 text-center mobile-only">
                <h4>
                  <b>Contact Us</b>
                </h4>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-3 facebook-icon py-3">
                    <a
                      href="https://www.facebook.com/Modcartio-110745874748143"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <FacebookIcon style={{ fontSize: "36px" }} />
                    </a>
                  </div>
                  <div className="col-3 insta-icon py-3">
                    <a
                      href="https://www.instagram.com/modcart.io/"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <InstagramIcon style={{ fontSize: "36px" }} />
                    </a>
                  </div>
                  {/* <div className="col-2 py-3">
                <LinkedInIcon style={{ fontSize: "36px" }} />
              </div> */}
                  <div className="col-3"></div>
                </div>
              </div>
              <div className="col-md-2 mt-5 text-center desktop-only">
                <h4>
                  <b>Contact Us</b>
                </h4>
                <div className="row ">
                  <div className="col-3"></div>
                  <div className="col-3 facebook-icon py-3">
                    <a
                      href="https://www.facebook.com/Modcartio-110745874748143"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <FacebookIcon style={{ fontSize: "36px" }} />
                    </a>
                  </div>
                  <div className="col-3 insta-icon py-3">
                    <a
                      href="https://www.instagram.com/modcart.io/"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <InstagramIcon style={{ fontSize: "36px" }} />
                    </a>
                  </div>
                  {/* <div className="col-2 py-3">
                <LinkedInIcon style={{ fontSize: "36px" }} />
              </div> */}
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="credits col-12 text-center">
            © 2020-2021 MODCART.IO - All rights reserved.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
