import React, { lazy, Suspense } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
// import { hot } from "react-hot-loader/root";
import { ConfigProvider, Spin } from "antd";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import config from "./config/config";
import store from "./redux/store";
import "./static/css/style.css";

import Home from "container/LandingHome/home";
import MySnackbar from "./components/Snackbar/Snackbar.js";
import Page404 from "Pages/Page404";
import TermsAndConditions from "Pages/TermsAndConditions/TermsAndConditions";
// import ProMildOnline from "microsites/promild/online/ProMildOnline";
// import ProMildOffline from "microsites/promild/offline/ProMildOffline";

// const Home = lazy(() => import("container/LandingHome/home"));
const Clear = React.lazy(() => import("./Clear"));
const Payment = React.lazy(() => import("Pages/Payment"));
// const BliBli = lazy(() => import("Pages/BrandPages/BliBli/index.js"));
const Lazada = lazy(() => import("Pages/BrandPages/Lazada/index.js"));
const Allianz = lazy(() => import("Pages/BrandPages/Allianz/index.js"));
const ReceiptForm = lazy(() => import("Pages/ReceiptForm/ReceiptForm"));
const ReceiptFormNew = lazy(() =>
  import("Pages/ReceiptFormNew/ReceiptFormNew")
);
const PrivacyPolicy = lazy(() => import("Pages/PrivacyPolicy/PrivacyPolicy"));
const Poll = lazy(() => import("Pages/SlidoPoll/Poll.js"));
const PublishedTheme = lazy(() =>
  import("container/OnePageStore/PublishedTheme")
);
const AllianzData = lazy(() => import("Pages/BrandPages/Allianz/AllianzData"));

// import ProMildOnline from "microsites/promild/online/ProMildOnline";
const ProMildOnline = lazy(() =>
  import("microsites/promild/online/ProMildOnline")
);
const ProMildOffline = lazy(() =>
  import("microsites/promild/offline/ProMildOffline")
);

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      darkMode: state.ChangeLayoutMode.data,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const [path, setPath] = useState(window.location.pathname);

  return (
    <Suspense fallback={<Spin />}>
      <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
        <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
          <Router>
            <Switch>
              {!window.location.pathname[1] && (
                <Route exact path="/" component={Home} />
              )}
              <Route exact path="/clearCookie" component={Clear} />
              <Route exact path="/receipt" component={ReceiptForm} />
              <Route exact path="/receipt_details" component={ReceiptFormNew} />
              <Route exact path="/privacypolicy" component={PrivacyPolicy} />
              <Route exact path="/termsAndConditions" component={TermsAndConditions} />

              <Route exact path="/payment" component={Payment} />
              {/* <Route exact path="/blibliseller" component={BliBli} /> */}
              <Route path="/lazada" component={Lazada} />
              <Route path="/allianz" component={Allianz} />
              <Route exact path="/survey" component={Poll} />
              <Route
                exact
                path="/eyJ1c2VySWQiOiI2Mzg0ODEwNW"
                component={AllianzData}
              />

              {/* Micro-site links */}
              <Route exact path="/promild/online" component={ProMildOnline} />
              <Route exact path="/promild/offline" component={ProMildOffline} />

              <Route exact path="/:name" component={PublishedTheme} />
              <Route path="*" component={Page404} />
            </Switch>
          </Router>
          <MySnackbar />
        </ThemeProvider>
      </ConfigProvider>
    </Suspense>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

// export default hot(App);
export default App;
