import React from "react";
import "./styles.css";
import termsAndConditions from "./TermsAndConditionsDetails";

const DetailedPrivacyPolicy = () => {
  return (
    <>
      <div className="col-12 text-align-start mb-5">
        <div id="policy-content" style={{height: "unset"}}>
          {termsAndConditions.map((section, index) => (
            <div key={index}>
              {section.title.length > 0 && (
                <h3 className="policySubHeading">{section.title}</h3>
              )}
              {section.sections.map((subSection, subIndex) => (
                <div key={subIndex}>
                  {subSection.content.map((content, contentIndex) => (
                    <p key={contentIndex} className="policyPara">
                      {content}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailedPrivacyPolicy;
