import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background: "linear-gradient(214.04deg, #05beff 2.7%, #485df5 102.74%)",
    color: theme.palette.primary.contrastText,
  },
  content: {
    textAlign: "center",
    "& h1": {
      fontWeight: "bold",
      color: "#fff",
      marginBottom: theme.spacing(2),
    },
    "& p": {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "#000",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const Page404 = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={6} lg={4} className={classes.content}>
        <Typography variant="h1">404</Typography>
        <Typography variant="body1">
          Oops! We can't seem to find the page you're looking for.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          href="/"
        >
          Go Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default Page404;
