import axios from "axios";
import _ from "lodash";

export const base = process.env.REACT_APP_BASE;
// export const base = "http://localhost:5000";
export const SET_PUBLISHED_DATA = "SET_PUBLISHED_DATA";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SET_PRICE_DATA = "SET_PRICE_DATA";

export const getPublishedData = (params) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    let { data } = await axios.get(`${base}/product/show?DomainId=${params}`);

    // console.log('data 1:>> ', data);
    data = data.result[0];
    // console.log('data 2:>> ', data);
    data.productDetails = _.shuffle(data.productDetails);
    // console.log('data 3:>> ', data);

    dispatch({ type: STOP_LOADING });
    return dispatch({ type: SET_PUBLISHED_DATA, payload: data });
  } catch (error) {
    dispatch({ type: STOP_LOADING });
    console.log(error.message);
  }
};

export const setPriceData = (data) => (dispatch) =>
  dispatch({ type: SET_PRICE_DATA, payload: data });
