import { SET_LANG } from "./action.type";

const initstate = {
    lang: null,
    country: null
}

export default function langReducer(state = initstate, action) {
    const { type, payload, country } = action;
    switch (type) {
        case SET_LANG: return { ...state, lang: payload, country: country }
        default: return state;

    }
}