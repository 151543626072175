import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Dropdown } from "components/dropdown/dropdown";
import React from "react";
import { Link } from "react-router-dom";
import india from "../../static/img/flag/india.png";
import indonesia from "../../static/img/flag/indonesia.png";
import phillipines from "../../static/img/flag/philli.png";
import vietnam from "../../static/img/flag/vietnam.png";

const Navbar = (props) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, right: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "right" === "top" || "right" === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer("right", false)}
      onKeyDown={toggleDrawer("right", false)}
    >
      <List className="nav-l mt-5">
        <Divider />
        <a
          href="https://app.modcart.io/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <ListItem button>
            <ListItemIcon>
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
            </ListItemIcon>
            <ListItemText primary={"Login"} />
          </ListItem>
        </a>
        <Divider />
        <a
          href="https://app.modcart.io/auth/signup"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <ListItem button>
            <ListItemIcon>
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
            </ListItemIcon>
            <ListItemText primary={"Sign Up"} />
          </ListItem>
        </a>
        <Divider />

        <Divider />
      </List>
    </Box>
  );

  const countryFlag = (country) => {
    switch (country) {
      case "India":
        return india;
      case "Indonesia":
        return indonesia;
      case "Phillipines":
        return phillipines;
      case "Vietnam":
        return vietnam;

      default:
        return india;
    }
  };

  console.log(props);
  return (
    <div className="nav-container">
      <div className="row">
        <div className="col-4 nav-logo">
          <a href="https://www.modcart.io/">
            <img src="https://cdn.modcart.io/landing/logo.png" alt='modcart' />
          </a>
        </div>
        <div className="col-8">
          <div className="nav-btn">
            <a
              href="https://app.modcart.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="btn login-txt desktop-only">LOGIN</div>
            </a>
            <a
              href="https://app.modcart.io/auth/signup"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="btn login-btn desktop-only">SIGN UP</div>
            </a>

            <Dropdown
              placement="bottomRight"
              content={props.country}
              trigger="click"
            >
              <Link
                to="#"
                className="head-example"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  style={{
                    width: 25,
                    marginRight: 5,
                    height: 18,
                    // borderRadius: "50%",
                  }}
                  src={countryFlag(props.flag)}
                  alt=""
                />{" "}
                {/* <span style={{ marginTop: 0 }}>
                  {props.language && props.language.lang !== null
                    ? props.language.lang.props.language_title
                    : ""}
                </span> */}
              </Link>
            </Dropdown>

            <Button
              onClick={toggleDrawer("right", true)}
              className="mobile-only p-0"
              style={{ color: "#fff", fontSize: 24, marginTop: "5px" }}
            >
              <Menu />
            </Button>
          </div>
        </div>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </div>
      {/* <nav class="navbar navbar-expand-lg navbar-dark bg-light p-0 desktop-only">
        <a class="navbar-brand" href="#">
          <div className="nav-logo">LOGO</div>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item ml-auto">
              <div className="btn login-btn">LOGIN</div>
            </li>
          </ul>
        </div>
      </nav> */}
    </div>
  );
};

export default Navbar;
