import { NavAuth } from "components/utilities/auth-info/auth-info-style";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setLang } from "redux/lang/action";
import languageData from "utility/lang.json";

import Footer from "./footer";
import Navbar from "./navbar";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import india from "../../static/img/flag/india.png";
import indonesia from "../../static/img/flag/indonesia.svg";
import phillipines from "../../static/img/flag/philli.png";
import vietnam from "../../static/img/flag/vietnam.png";
// import SliderCustom from "./Slider/SliderCustom";

const flag = localStorage.getItem("country");

const Home = (props) => {
  const { lang: language } = props;
  // console.log(props.lang);
  const dispatch = useDispatch();

  const lang = localStorage.getItem("lang");
  useEffect(() => {
    if (lang && lang !== null && lang !== undefined && !props.lang.lang) {
      dispatch(setLang(lang, flag && flag !== undefined ? flag : "Indonesia"));
    }
    if (!lang) {
      const jsonData = JSON.stringify(languageData["en"]);
      dispatch(setLang(jsonData, "Indonesia"));
    }
    // eslint-disable-next-line 
  }, [dispatch, lang, props.lang]);

  const langs = useSelector((state) => state.lang.lang);

  console.log(langs);

  const onFlagChangeHandle = (value, country) => {
    dispatch(setLang(JSON.stringify(value), country));
  };

  const link = (lgd, name, src, lang) => <Link
    onClick={() => onFlagChangeHandle(languageData[lgd], name)}
    to="#">
    <img loading="lazy" style={{ width: 25 }} src={src} alt="flag" />
    <span>{lang}</span>
  </Link>

  const country = (
    <NavAuth>
      {link('en', "Indonesia", indonesia, "English")}
      {link('en', "India", india, "English")}
      {link('indo', "Indonesia", indonesia, "Bahasa")}
      {link('en', "Phillipines", phillipines, "English")}
      {link('en', "Vietnam", vietnam, "English")}
    </NavAuth>
  );

  const countryFlag = (country) => {
    switch (country) {
      case "India":
        return india;
      case "Indonesia":
        return indonesia;
      case "Phillipines":
        return phillipines;
      case "Vietnam":
        return vietnam;

      default:
        return india;
    }
  };

  // const navDrop = () => {
  //   return (
  //     <Dropdown placement="bottomRight" content={country} trigger="click">
  //       <Link
  //         to="#"
  //         className="head-example"
  //         style={{ display: "flex", alignItems: "center" }}
  //       >
  //         <img
  //           style={{ width: 35, marginRight: 5, height: "100%" }}
  //           src={countryFlag(flag)}
  //           alt=""
  //         />{" "}
  //         <span style={{ marginTop: 0 }}>
  //           {language && language.lang !== null
  //             ? language.lang.language_title
  //             : ""}
  //         </span>
  //       </Link>
  //     </Dropdown>
  //   );
  // };

  return (
    <React.Fragment>
      <Navbar
        country={country}
        flag={flag}
        countryFlag={countryFlag}
        language={language}
      />
      {/* <div style={{ position: "fixed", zIndex: 999, right: 0 }}> */}

      {/* </div> */}
      <div className="home">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 home-container">
              <div className="heading">
                {" "}
                {langs && langs.landing_title_1}{" "}
                <span style={{ color: "#FFD601" }}>
                  {langs && langs.landing_title_2}
                </span>{" "}
                {langs && langs.landing_title_3}
              </div>
              <div className="sub-heading mt-3">
                {langs && langs.landing_subtitle}
              </div>
              <div className="btn-container">
                <a href="https://app.modcart.io/" target="_blank" rel="noopener noreferrer">
                  <div className="btn home-btn mt-4">
                    Get Started For{" "}
                    <span style={{ color: "#FFD601" }}>Free</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-5">
              {/* <video className="home-vid" autoPlay loop muted playsinline>
                <source src="https://cdn.modcart.io/landing/home.webm" type="video/webm" />
              </video> */}
              <img
                src="https://cdn.modcart.io/landing/home.gif"
                className="home-vid"
                alt='home'
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", padding: "16px 24px" }} >
        {/* <SliderCustom /> */}
      </div>
      <section>
        <div className="container-fluid">
          <div
            className="row flex-column-reverse flex-md-row"
            data-aos="fade-up"
            data-aos-duration="1500">
            <LazyLoadComponent>
              <div className="col-md-6">
                {/* <img className="section-1-img" src="https://cdn.modcart.io/landing/section1.png" /> */}
                <video className="section-1-vid" playsInline autoPlay muted loop>
                  <source src="https://cdn.modcart.io/landing/section-1-2.mp4" />
                </video>
                {/* <img
                className="section-1-vid"
                src="https://cdn.modcart.io/landing/section-1.gif"
              /> */}
              </div>
            </LazyLoadComponent>
            <div className="col-md-6">
              <div className="content">
                <div className="section-1-heading">
                  {langs && langs.section_1_title}
                </div>
                <div className="section-1-text mt-3">
                  {langs && langs.section_1_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row " data-aos="fade-up" data-aos-duration="1500">
            <div className="col-md-6 ">
              <div className="content">
                <div className="section-2-heading">
                  {" "}
                  {langs && langs.section_2_title}
                </div>
                <div className="section-2-text mt-3">
                  {langs && langs.section_2_desc}
                </div>
              </div>
            </div>
            <LazyLoadComponent>
              <div className="col-md-6">
                <video className="section-2-vid" playsInline autoPlay muted loop>
                  <source src="https://cdn.modcart.io/landing/section-2-2.mp4" />
                </video>
              </div>
            </LazyLoadComponent>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div
            className="row  flex-column-reverse flex-md-row"
            data-aos="fade-up"
            data-aos-duration="1500">
            <LazyLoadComponent>
              <div className="col-md-6">
                <video className="section-3-vid" playsInline autoPlay muted loop>
                  <source src="https://cdn.modcart.io/landing/section-3-2.mp4" />
                </video>
              </div>
            </LazyLoadComponent>
            <div className="col-md-6 ">
              <div className="content">
                <div className="section-2-heading">
                  {" "}
                  {langs && langs.section_3_title}
                </div>
                <div className="section-2-text mt-3">
                  {langs && langs.section_3_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row" data-aos="fade-up" data-aos-duration="1500">
            <div className="col-md-6 ">
              <div className="content">
                <div className="section-1-heading">
                  {langs && langs.section_4_title}
                </div>
                <div className="section-1-text mt-3">
                  {langs && langs.section_4_desc}
                </div>
              </div>
            </div>
            <LazyLoadComponent>
              <div className="col-md-6">
                <img
                  className="section-3-img"
                  src="https://cdn.modcart.io/landing/section-4.png"
                  alt='section4'
                />
              </div>
            </LazyLoadComponent>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div
            className="row flex-column-reverse flex-md-row"
            data-aos="fade-up"
            data-aos-duration="1500">
            <LazyLoadComponent>
              <div className="col-md-6">
                <img
                  className="section-4-img"
                  src="https://cdn.modcart.io/landing/section-5.png"
                  alt='section5'
                />
              </div>
            </LazyLoadComponent>
            <div className="col-md-6">
              <div className="content">
                <div className="section-2-heading">
                  {" "}
                  {langs && langs.section_5_title}
                </div>
                <div className="section-2-text mt-3">
                  {langs && langs.section_5_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-12 text-center mt-3 mb-5">
        <h1>
          <b> {langs && langs.one_liner}</b>
        </h1>
        <a href="https://app.modcart.io/" target="_blank" rel="noopener noreferrer">
          <div className="btn btn-last mt-4 mb-5">
            {langs && langs.last_btn}
          </div>
        </a>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Home);
