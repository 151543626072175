import { SET_PRICE_DATA, SET_PUBLISHED_DATA, START_LOADING, STOP_LOADING } from "./action"

const initstate = {
    loading: false,
    published: null,
    priceDetail: null
}

export default function publishedReducer(state=initstate, action){
    const { type, payload } = action
    switch (type) {
        case START_LOADING: return {...state, loading: true}
        case STOP_LOADING: return {...state, loading: false}
        case SET_PUBLISHED_DATA:  return {...state, published: payload};
        case SET_PRICE_DATA: 
            return {...state, priceDetail: payload }
        default: return state
    }
}