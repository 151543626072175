import { combineReducers } from "redux";
import { SelectedProduct } from "./CreateStore/reducer";
import langReducer from "./lang/lang.reducer";
import publishedReducer from "./published/reducer";
import SnackBarReducer from "./Snackbar/SnackBarReducer";
import ChangeLayoutMode from "./themeLayout/reducers";

const rootReducers = combineReducers({
  SelectedProduct: SelectedProduct,// only in theme 1.. remove from there

  lang: langReducer, // language control
  snackbar: SnackBarReducer, // snackbar
  ChangeLayoutMode, // for layout in app.js
  published: publishedReducer, //publisehd theme
});

export default rootReducers;
